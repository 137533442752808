import { Injectable } from "@angular/core";
import { ILoyaltyPage } from "shared/interfaces/loyalty-page";
import { ILoyaltyUser } from "shared/interfaces/loyalty-user";

@Injectable()
export class Globals {
  loyaltyPage: ILoyaltyPage
  user: ILoyaltyUser
  standalone: boolean
}
