import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeStyle, Title } from '@angular/platform-browser';
import { cloneDeep } from 'lodash';
import { ILoyaltyPage } from 'shared/interfaces/loyalty-page';
import { ILoyaltyUser } from 'shared/interfaces/loyalty-user';
import { CountryCode } from 'shared/types/country-code';
import { Api } from '../../services/api';
import { Globals } from '../../services/globals';
import { Styling } from '../../services/styling';
import { countryPhoneCodes, validatePhoneNumber } from '../../../../../../shared/helpers/phone';
import { CurrentUser } from '../../services/current-user';

@Component({
  selector: 'user-section-content',
  templateUrl: './user-section-content.component.html',
  styleUrls: ['./user-section-content.component.scss']
})
export class UserSectionContentComponent {
  phone: string;
  verificationCode: string;
  verificationStarted: boolean;

  checkingCode: boolean;

  completeRegistrationUser: ILoyaltyUser;
  countryCode = '+46';
  countryCodes = Object.values(countryPhoneCodes);

  @ViewChild('phoneInput')
  phoneInput: ElementRef<HTMLInputElement>;

  @ViewChild('codeInput')
  codeInput: ElementRef<HTMLInputElement>;

  constructor(private api: Api, public globals: Globals, public title: Title, private bottomSheet: MatBottomSheet, private styling: Styling, private elementRef: ElementRef, private sanitizer: DomSanitizer, private currentUser: CurrentUser) {

  }

  async ngOnInit() {
    let user = await this.currentUser.getUser();
    if (!user) {
      setTimeout(() => {
        console.log(this.phoneInput);
        this.phoneInput.nativeElement.focus();
      }, 500);
    }
    else {
      this.completeRegistrationUser = cloneDeep(this.globals.user);
    }
  }

  validateVerificationCode() {
    return this.verificationCode && this.verificationCode.length == 6;
  }

  async submitPhone() {
    this.verificationStarted = true;
    setTimeout(() => {
      console.log(this.codeInput);
      this.codeInput.nativeElement.focus();
    }, 200);

    let result = await this.api.loyalty().post('/verify-phone', { phone: this.phone });
  }

  async submitCode() {
    this.checkingCode = true;
    let result = await this.api.loyalty().post<any>('/verify-phone/submit-code', { code: this.verificationCode });

    if (result.succeeded) {
      this.globals.user = result.user;
      localStorage.setItem('user-access-token', result.accessToken);

      if (!this.globals.user.registrationCompleted)
        this.completeRegistrationUser = cloneDeep(this.globals.user);
    }
  }

  validateRegistrationCompletion() {
    return true;
  }

  validatePhone() {
    return validatePhoneNumber(this.getPhone());
  }

  getPhone() {
    let phone = this.phone || '';
    if (phone.startsWith('0'))
      phone = phone.substring(1);

    if (!phone.startsWith('+'))
      return this.countryCode + phone;
    else
      return phone;
  }

  async finishRegistration() {
    let result = await this.api.loyalty().post<any>('/current-user/finish-registration', { user: this.completeRegistrationUser });
  }




}
