//import phone from 'phone';
import parsePhoneNumber from 'libphonenumber-js';
import { CountryCode } from '../types/country-code';



export const countryPhoneCodes = {
  se: '+46',
  no: '+47',
  dk: '+45',
  de: '+49',
  nl: '+31',
  ch: '+41'
}


export const formatPhoneNumber = (number: string, countryCode?: CountryCode) => {
  try {
    if (number) {
      // Remove hyphens
      number = number.replace('-', '');

      // Remove leading zeroes
      if (number.startsWith('00'))
        number = number.substring(2);

      // Add country code
      if (!number.startsWith('+'))
        number = countryPhoneCodes[countryCode] + (number.startsWith('0') ? number.substring(1) : number);

      //   console.log(number);
      //let phoneNumber : any = phone(countryCode, number);
      //console.log(phone);
      let phoneNumber = parsePhoneNumber(number, countryCode as any);
      console.log(phoneNumber);
      return phoneNumber.number;
      //console.log(phoneNumbers[0]);

      /*
      let phoneNumber = parsePhoneNumberFromString(phone, countryCode ? (countryCode.toUpperCase() as any) : null)
      if (phoneNumber) {
          if (countryCode) {
             // phoneNumber.country = 's' //  = (countryCode.toUpperCase() as any);
              return phoneNumber.formatInternational();
          } else {
              return phoneNumber.formatInternational();
          }
      }
      else {
          //console.log('Phone number null');
          return '-';
      }
      */
    }
  }
  catch (error) {
    console.log('Phone error:');
    console.log(error);
    return null;
  }
}

export const validatePhoneNumber = (number: string) => {
  try {
    if (number) {
     // let phoneNumber: any = phone(null, number);
     let phoneNumber = parsePhoneNumber(number);
     return phoneNumber && phoneNumber.isValid();
    }
  }
  catch (error) {
    console.log('Phone error:');
    console.log(error);
    return null;
  }
}
