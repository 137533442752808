import { NgModule } from '@angular/core';
import { AppModule} from './app.module';
import { AppComponent } from './app.component';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { AppRoutingModule } from './app-routing.client.module';


@NgModule({
    imports: [
        AppModule,
        AppRoutingModule
    ],
    providers: [

    ],
    bootstrap: [AppComponent],
})
export class LoyaltyAppClientModule { }
