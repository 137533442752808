import { Injectable } from "@angular/core";
import { ILoyaltyPage } from "shared/interfaces/loyalty-page";
import { ILoyaltyUser } from "shared/interfaces/loyalty-user";
import { Api } from "./api";
import { Globals } from "./globals";

@Injectable()
export class CurrentUser {
  userPromise: Promise<ILoyaltyUser>;

  constructor(private globals: Globals, private api: Api) {

  }

  async getUser() {
    try {
      if (this.globals.user) {
        return this.globals.user;
      }
      else if (this.userPromise) {
        return this.userPromise;
      }
      else {
        this.userPromise = this.api.loyalty().get<ILoyaltyUser>('/current-user');
        this.globals.user = await this.userPromise;
        return this.globals.user;
      }
    }
    catch (e) {
      return null;
    }
  }

  async userLoaded(callback: (user: ILoyaltyUser) => void) {
    if (this.globals.user)
      callback(this.globals.user);
    else
      callback(await this.getUser());
  }

}
