import { Component, Inject } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";

@Component({
  selector: 'install-app-prompt',
  templateUrl: 'install-app-prompt.component.html',
})
export class InstallAppPromptComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef<InstallAppPromptComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: { accept: any, dismiss: any }) { }

  close() {
    this.bottomSheetRef.dismiss();
  }

  async accept() {
    console.log('Accept');
    this.data.accept();
    this.close();
  }

  async dismiss() {
    console.log('Dismiss');
    this.data.dismiss();
    this.close();
  }
}


