<mat-sidenav-container *ngIf="loyaltyPage" [hasBackdrop]="true" (backdropClick)="closeMenu()">
  <mat-sidenav class="print-hide" #sidenav mode="over" position="end" [(opened)]="menuOpened">
    <div class="menu">
      <div>
        <a [routerLink]="['/start']">
          Sida 1
        </a>
      </div>
      <div>
        <a [routerLink]="['/blubb']">
          Sida 2
        </a>
      </div>
      <div>Sida 3</div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="container">
          <header>
            <div class="row">
              <div class="col">
                <div class="logo" *ngIf="loyaltyPage.designSettings.logoImage" [style.backgroundImage]="logoImage">
                </div>
              </div>
              <div class="col align-right">
                <mat-icon class="menu-icon clickable" (click)="openMenu()">menu</mat-icon>
              </div>
            </div>
          </header>
          <div class="content">
            <router-outlet></router-outlet>
          </div>
        </div>
  </mat-sidenav-content>
</mat-sidenav-container>
