import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';


@Injectable()
export class Api {
    constructor(private http: HttpClient,
        @Inject(PLATFORM_ID) private platformID: Object) {

    }

    private requester = (baseUrl: string) => {
        let http = this.http;
        let headers: any = {};

        // console.log(this.req);
        if (isPlatformServer(this.platformID)) {
            //  console.log(this.req.headers['cookie']);
            /*   let cookies = [];
               this.req.headers['cookie'].forEach((c: string) => {
                   cookies.push(c);
               }); */
           // if (this.req.headers['cookie'])
           //     headers['cookie'] = this.req.headers['cookie']; //cookies.join(';');
        }
        return {
            get: function <T>(url: string) {
                return http.get(`${baseUrl}${url}`, {
                    withCredentials: true,
                    headers: headers
                }).toPromise() as Promise<T>
            },
            post: function <T>(url: string, data: any) {
                return http.post(`${baseUrl}${url}`, data, {
                    withCredentials: true,
                    headers: headers
                }).toPromise() as Promise<T>
            },
            put: function <T>(url: string, data: any) {
                return http.put(`${baseUrl}${url}`, data, {
                    withCredentials: true,
                    headers: headers
                }).toPromise() as Promise<T>
            },
            patch: function <T>(url: string, data: any) {
                return http.patch(`${baseUrl}${url}`, data, {
                    withCredentials: true,
                    headers: headers
                }).toPromise() as Promise<T>
            },
            delete: function <T>(url: string) {
                return http.delete(`${baseUrl}${url}`, {
                    withCredentials: true,
                    headers: headers
                }).toPromise() as Promise<T>
            }
        }
    }


    public loyalty = () => {
        return this.requester(`/api/loyalty`);
    }

    public base = () => {
        return this.requester(`/api`);
    }


}
