<div>
  <div class="row align-center">
    <div class="col-12">
      <h3>Installera gärna vår app!</h3>
    </div>
    <div class="col-6 align-right">
      <button class="negative" mat-raised-button (click)="dismiss()">Nej tack</button>
    </div>
    <div class="col-6 align-left">
      <button class="positive" mat-raised-button (click)="accept()">Installera</button>
    </div>
  </div>
</div>
