import { Component, ElementRef } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeStyle, Title } from '@angular/platform-browser';
import { ILoyaltyPage } from 'shared/interfaces/loyalty-page';
import { environment } from '../environments/environment';
import { InstallAppPromptComponent } from './components/install-app-prompt/install-app-prompt.component';
import { Api } from './services/api';
import { Globals } from './services/globals';
import { Styling } from './services/styling';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  primaryColor: string;
  deferredInstallPrompt;
  installPromptRef: MatBottomSheetRef<InstallAppPromptComponent>;
  installPromptHidden: boolean;
  loyaltyPage: ILoyaltyPage;
  menuOpened: boolean;

  logoImage: SafeStyle;
  prod: boolean;
  constructor(private api: Api, public globals: Globals, public title: Title, private bottomSheet: MatBottomSheet, private styling: Styling, private elementRef: ElementRef, private sanitizer: DomSanitizer) {
    this.prod = environment.production;
  }

  async ngOnInit() {
    window.addEventListener('beforeinstallprompt', async (e) => {
      console.log('Before install');
      console.log(e);
      this.deferredInstallPrompt = e;

      const { outcome } = await this.deferredInstallPrompt.userChoice;
      if (outcome === 'accepted') {
        this.hideInstallPrompt();
      }

      setTimeout(() => {
        if (!this.installPromptHidden)
          this.showInstallPrompt();
      }, 1000);

    });

    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log('Standalone');
      this.globals.standalone = true;
    }

    this.primaryColor = JSON.stringify(await this.api.loyalty().get('/design'));
    this.loyaltyPage = this.globals.loyaltyPage = await this.api.loyalty().get('/');
    let pwaManifest = await this.api.loyalty().get<any>('/manifest');
    let favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    favIcon.href = pwaManifest.icons.find(i => i.sizes == '72x72').src;
    favIcon.type = pwaManifest.icons.find(i => i.sizes == '72x72').type;
    this.title.setTitle(this.globals.loyaltyPage.name);

    if (this.loyaltyPage.designSettings.logoImage)
      this.logoImage = this.sanitizer.bypassSecurityTrustStyle(`url("${this.loyaltyPage.designSettings.logoImage.medium.url}")`);

    this.createStyle();
  }

  async showInstallPrompt() {
    console.log('Showing install prompt');
    this.installPromptRef = this.bottomSheet.open(InstallAppPromptComponent, {
      hasBackdrop: false,
      data: {
        accept: () => {
          if (this.deferredInstallPrompt) {
            this.deferredInstallPrompt.prompt();
          } else {
            console.log('No deferred install prompt');
          }
          this.installPromptRef.dismiss();
        },
        dismiss: () => {
          this.installPromptRef.dismiss();
        }
      }
    });

    console.log('Prompt ref:');
    console.log(this.installPromptRef);
  }

  async hideInstallPrompt() {
    if (this.installPromptRef)
      this.installPromptRef.dismiss();
    this.installPromptHidden = true;
  }

  async createStyle() {
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(await this.styling.getLoyaltyPageCss(this.globals.loyaltyPage)));
    document.querySelector('body').appendChild(styleElement);
  }

  async installApp() {

  }

  openMenu() {
    this.menuOpened = true;
  }

  closeMenu() {
    this.menuOpened = false;
  }


}
