import { Component, ElementRef } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeStyle, Title } from '@angular/platform-browser';
import { ILoyaltyPage } from 'shared/interfaces/loyalty-page';
import { Api } from '../../services/api';
import { Globals } from '../../services/globals';
import { Styling } from '../../services/styling';

@Component({
  selector: 'start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent {

  constructor(private api: Api, public globals: Globals, public title: Title, private bottomSheet: MatBottomSheet, private styling: Styling, private elementRef: ElementRef, private sanitizer: DomSanitizer) {

  }

  async ngOnInit() {

  }




}
