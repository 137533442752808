<div>

  <!-- LOGIN -->
  <div *ngIf="!globals.user" class="align-center">
    <ng-container *ngIf="!verificationStarted">
      <h2>Logga in eller bli medlem</h2>
      <!-- asdf -->
      <mat-form-field class="country-code-input">
        <mat-label>&nbsp;</mat-label>
        <mat-select [(ngModel)]="countryCode">
          <mat-option *ngFor="let code of countryCodes" [value]="code">{{code}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Telefonnummer</mat-label>
        <input matInput #phoneInput type="text" [(ngModel)]="phone" />
      </mat-form-field>
      <br />
      <button class="positive" mat-button [disabled]="!validatePhone()" (click)="submitPhone()">Skicka
        inloggningskod</button>
    </ng-container>

    <ng-container *ngIf="verificationStarted">
      <ng-container *ngIf="!checkingCode">
        <h2>Ett SMS med inloggningskod har skickats</h2>
        <mat-form-field>
          <mat-label>Inloggningskod</mat-label>
          <input matInput #codeInput type="text" [(ngModel)]="verificationCode" />
        </mat-form-field>
        <br />
        <button class="positive" mat-raised-button [disabled]="!validateVerificationCode()" (click)="submitCode()">Ange
          inloggningskod</button>
      </ng-container>

      <div class="align-center" *ngIf="checkingCode">
        <mat-progress-spinner class="inline-block" [mode]="'indeterminate'"></mat-progress-spinner>
      </div>
    </ng-container>

  </div>

  <!-- USER LOGGED IN -->
  <div *ngIf="globals.user">
    <!-- REGISTRATION COMPLETED -->
    <div *ngIf="globals.user.registrationCompleted">

    </div>

    <!-- REGISTRATION NOT COMPLETED -->
    <div *ngIf="!globals.user.registrationCompleted">
      <div class="row">
        <div class="col-12">
          <h2>Komplettera dina uppgifter</h2>
        </div>
        <div class="col-md-6 col-sm-4">
          <mat-form-field>
            <mat-label>Förnamn</mat-label>
            <input matInput type="text" [(ngModel)]="completeRegistrationUser.firstName" />
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-4">
          <mat-form-field>
            <mat-label>Efternamn</mat-label>
            <input matInput type="text" [(ngModel)]="completeRegistrationUser.lastName" />
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-4">
          <mat-form-field>
            <mat-label>Epost</mat-label>
            <input matInput type="email" [(ngModel)]="completeRegistrationUser.email" />
          </mat-form-field>
        </div>
      </div>
      <br />
      <button class="positive" mat-raised-button [disabled]="!validateRegistrationCompletion()"
        (click)="finishRegistration()">Spara</button>
    </div>
  </div>
</div>
