import { Injectable } from "@angular/core";
import { DesignCssStyle, ILoyaltyPage } from "shared/interfaces/loyalty-page";

@Injectable()
export class Styling {


  async getLoyaltyPageCss(loyaltyPage: ILoyaltyPage) {
    let googleFonts: string[] = [];
    let getRule = (selectors: string[], cssStyle: DesignCssStyle) => {
      let result = '';

      result += `${selectors.join(', ')}{
        background-color:${cssStyle.backgroundColor};
        color: ${cssStyle.color};
      }`;

      if (cssStyle.googleFont && cssStyle.googleFont != '') {
        result += `font-family: '${cssStyle.googleFont}';`;
      }

      result += `${selectors.map(selector => `${selector} .mat-form-field-label`).join(', ')}{
        color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-form-field-appearance-legacy .mat-form-field-label`).join(', ')}{
        color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-focused .mat-form-field-label`).join(', ')}{
        color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-form-field-underline`).join(', ')}{
        background-color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-focused .mat-form-field-underline`).join(', ')}{
        background-color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-form-field-ripple`).join(', ')}{
        background-color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-focused .mat-form-field-ripple`).join(', ')}{
        background-color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-select-value`).join(', ')}{
        color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-focused .mat-select-value`).join(', ')}{
        color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-select-arrow`).join(', ')}{
        color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-focused .mat-select-arrow`).join(', ')}{
        color: ${cssStyle.color};
      }`;

      result += `${selectors.map(selector => `${selector} .mat-progress-spinner`).join(', ')}{
        color: ${cssStyle.color};
      }`;


      if (cssStyle.googleFont && !googleFonts.includes(cssStyle.googleFont))
        googleFonts.push(cssStyle.googleFont);



      return result;
    }

    let getButtonRule = (selectors: string[], cssStyle: DesignCssStyle) => {
      let result = '';

      result += `${selectors.join(', ')}{
        background-color:${cssStyle.backgroundColor};
        color: ${cssStyle.color};
      }`;

      if (cssStyle.googleFont && cssStyle.googleFont != '') {
        result += `font-family: '${cssStyle.googleFont}';`;
      }

      if (cssStyle.googleFont && !googleFonts.includes(cssStyle.googleFont))
        googleFonts.push(cssStyle.googleFont);

      return result;
    }




    let css = `
      ${getRule(['body', 'body .mat-drawer-container'], loyaltyPage.designSettings.body)}
      ${getRule(['header'], loyaltyPage.designSettings.header)}
      ${getRule(['.menu'], loyaltyPage.designSettings.menu)}

      ${getButtonRule(['button.positive'], loyaltyPage.designSettings.buttons.positive)}
      ${getButtonRule(['button.neutral'], loyaltyPage.designSettings.buttons.neutral)}
      ${getButtonRule(['button.negative'], loyaltyPage.designSettings.buttons.negative)}
    `;

    let googleFontData = '';
    for (let googleFont of googleFonts) {
      googleFontData += `&family=${googleFont.replace(/ /g, '+')}:wght@400;700`;
    }

    if (googleFontData != '') {
      googleFontData = `@import url('https://fonts.googleapis.com/css2?${googleFontData.substring(1)}&display=swap');`;
    }



    return `
    ${googleFontData}
    ${css}
    `
  }
}
