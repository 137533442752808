import { HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { Api } from './services/api';
import { Globals } from './services/globals';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { InstallAppPromptComponent } from './components/install-app-prompt/install-app-prompt.component';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Styling } from './services/styling';
import { StartComponent } from './components/start/start.component';
import { UserSectionContentComponent } from './components/user-section-content/user-section-content.component';
import { CurrentUser } from './services/current-user';

@NgModule({
  declarations: [
    AppComponent,
    InstallAppPromptComponent,
    StartComponent,
    UserSectionContentComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'loyaltyApp' }),
    RouterModule,
    HttpClientModule,
    TransferHttpCacheModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatBottomSheetModule,
    MatIconModule,
    FormsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatTabsModule,
    MatSnackBarModule,
    MatCardModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule
  ],
  providers: [
    Api,
    Globals,
    Styling,
    CurrentUser
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
